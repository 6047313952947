import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const TermsAndConditions = () => {
  useEffect(() => {
    window.location.replace(
      'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
    )
  }, [])

  return null
}

export default TermsAndConditions
